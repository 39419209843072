<template>
  <v-sheet :class="!styleTheme ? 'ma-3' : null" :color="styleTheme ? 'grey' : 'transparent'">
    <v-autocomplete
      v-model="customer"
      label="Müşteri"
      placeholder="Müşteri Seçin"
      persistent-placeholder
      :items="customerItems"
      :loading="customerLoading"
      :filled="styleTheme"
      :background-color="styleTheme ? 'grey' : 'transparent'"
      color="black"
      hide-details
      :rounded="styleTheme"
      item-text="name1"
      item-value="customer"
      @change="customerChange"
    >
      <template v-if="!$vuetify.breakpoint.mdAndDown" v-slot:prepend>
        <v-icon>{{ icons.mdiDomain }}</v-icon>
      </template>
      <template v-slot:item="{ item }" class="ahmet">
        <v-list-item-content>
          <v-list-item-title 
          :class="{
            'black--text': !isDark,
            'white--text': isDark, // isDark ise farklı bir stil uygula
            'font-weight-bold': true
          }"
          style="font-size: 15px;">         
            {{ item.name1 }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.customer  }}
          </v-list-item-subtitle>
          <!-- <v-list-item-subtitle>
            {{ item.address || 'Adres Bilgisi Yok' }}
          </v-list-item-subtitle> -->
        </v-list-item-content>
        <!--    <v-list-item-action>
          <v-chip color="chip" small label>
            {{ item.limit || 0 | currency }}
          </v-chip>
        </v-list-item-action>-->
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<script>
import { mdiDomain } from '@mdi/js'

import store from '@/store'
import { useRouter } from '@core/utils'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import useAppConfig from '@core/@app-config/useAppConfig'
export default {
  props: {
    styleTheme: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isDark } = useAppConfig()
    const { router } = useRouter()

    const customer = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    onMounted(() => {
      customerLoading.value = true

      const data = {
        method: 'getCustomersBySalDept',
        type: 'select',
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          customerItems.value = response.detail
          customer.value = store.state.plasiyerCustomer
        })
        .catch(e => {
          // loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })
    })

    const customerChange = () => {      
      debugger
      const data = {
        method: 'changeActiveCustomer',
        customer: customer.value,
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          if (response.error == 1) {
            store.commit('SECOND_STATE', false)
            Vue.swal({
              title: 'Uyarı',
              text: response.msg,
              width: '350px',
              icon: 'warning',
              background: '#FFB400',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          } else if (response.error == 2) {
            store.commit('PLASIYER_CUSTOMER', customer.value)
            store.commit('SECOND_STATE', true)
            store.commit('SECOND_MSG', response.msg)
            Vue.swal({
              title: 'Uyarı',
              html: store.state.secondMsg,
              icon: 'warning',
              background: '#FFB400',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            console.log(customer.value)
            router.push({ name: 'payment' })
          } else {
            store.commit('SECOND_STATE', false)
            store.commit('PLASIYER_CUSTOMER', customer.value)
          }
        })
        .finally(() => {
          customerLoading.value = false
        })
    }

    return {
      customerItems,
      customerLoading,
      customer,
      customerChange,
      isDark,
      icons: { mdiDomain },
    }
  },
}
</script>
<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  height: auto !important;
}
</style>
