<template>
  <v-btn
    style="height: 56px !important"
    :fab="!$vuetify.breakpoint.mdAndUp"
    depressed
    color="accent"
    rounded
    :x-large="$vuetify.breakpoint.mdAndUp"
    @click="basketClick()"
  >
    <v-badge v-if="basketItem()" :content="$store.state['app-order'].basketItemCount" color="error" overlap>
      <v-icon :size="26" :left="$vuetify.breakpoint.mdAndUp">
        {{ icons.mdiBasketOutline }}
      </v-icon>
    </v-badge>
    <v-icon v-else :size="26" :left="$vuetify.breakpoint.mdAndUp">
      {{ icons.mdiBasketOutline }}
    </v-icon>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-3 text-capitalize">Sepetiniz</div>
  </v-btn>
</template>

<script>
import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiBasketOutline } from '@mdi/js'
import { getCurrentInstance, onMounted, watch } from '@vue/composition-api'

export default {
  setup() {
    const vm = getCurrentInstance()?.proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const { isDark } = useAppConfig()

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    const basketItem = () => store?.state['app-order']?.basketItemCount > 0
    const basketClick = () => {    
      debugger  
      console.log("basketClick",store)
      store.commit('app/TOGGLE_BASKET_DRAWER', true)
    }
    onMounted(() => {
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
      } else {
        store.dispatch('app-order/fetchBasketItemCount').then(response => {
          if (response.error === 1) {
            logoutUser()
          }
        })
      }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        store.dispatch('app-order/fetchBasketItemCount').then(response => {
          if (response.error === 1) {
            logoutUser()
          }
        })
      },
    )

    return {
      isDark,
      basketClick,
      basketItem,

      // Icons
      icons: {
        mdiBasketOutline,
      },
    }
  },
}
</script>

<style></style>
